import { useAuthData } from '../../useAuthData';
import { useCheckUnleashFlag } from './useCheckUnleashFlag';

export interface Permissions {
  expenseManagement: boolean;
  approver: boolean;
  kilometer: boolean;
  contract: boolean;
  enabledExpensePermission: boolean;
  enabledTravelPreferences: boolean;
}

export interface UsePermissionsExpenses {
  userIsAdmin: boolean;
  permissions: Permissions;
}

export function useUnleashPermissions(): UsePermissionsExpenses {
  const { isAdmin } = useAuthData();
  const checkUnleashFlag = useCheckUnleashFlag();

  return {
    userIsAdmin: isAdmin,
    permissions: {
      expenseManagement: checkUnleashFlag('FLASH_OS_EXPENSE_MANAGEMENT'),
      approver: checkUnleashFlag('FLASH_OS_EXPENSE_MANAGEMENT_APPROVALS'),
      kilometer: checkUnleashFlag('FLASH_OS_EXPENSE_MANAGEMENT_KILOMETER'),
      contract: checkUnleashFlag('FLASH_OS_EXPENSE_MANAGEMENT_CONTRACTS'),
      enabledExpensePermission: checkUnleashFlag('FLASH_OS_EXPENSE_MANAGEMENT_PERMISSIONS'),
      enabledTravelPreferences: checkUnleashFlag('FLASH_OS_TRAVEL_PREFERENCES'),
    },
  };
}
