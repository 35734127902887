import { useContext } from 'react';
import { ApprovalType } from '@containers/Customizations/context/types/approvals';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { useFetch } from '@shared/hooks/service/useFetch';

interface IExpenseReportSettings {
  expirationDateType: 'MANUAL' | 'AUTOMATIC';
  expirationDateFlag: boolean;
  hasFinancialIntegration: boolean;
  approvalSettings: {
    approvalType: ApprovalType;
  };
}

export function useReportApprovalSettings() {
  const { companyId } = useContext(SessionContext);
  const { data, error, isLoading } = useFetch<IExpenseReportSettings>(
    `v1/settings/expense/report/defined?company=${companyId}`,
  );

  return {
    expirationDateType: data?.expirationDateType,
    expirationDateFlag: data?.expirationDateFlag,
    hasFinancialIntegration: data?.hasFinancialIntegration,
    approval: { approvalType: data ? ApprovalType[data.approvalSettings.approvalType] : ApprovalType.MANUAL_CHOICE },
    isLoading,
    error,
  };
}
