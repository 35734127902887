import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { BulkImportProjects } from '@pages/BulkImportProjects';
import { BulkImportProjectsDetails } from '@pages/BulkImportProjectsDetails';
import { ProjectRegister } from '@pages/PlatformSettings/Projects/ProjectRegister';
import { ProjectsList } from '@pages/PlatformSettings/Projects/Projects';
import { ImportingContextProvider } from '@shared/contexts/BulkImportContext';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function ProjectsRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_PROJECT']}
          routePermissions={[EAuthZPermission.CONFIGURATION_PROJECTS]}
        />
      ),
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Projetos" moduleName="Projetos" />,
      children: [
        {
          path: Router.getProjectRegister(),
          element: <ProjectRegister />,
        },
        {
          path: Router.getProjectRegister(':projectId'),
          element: <ProjectRegister />,
        },
        {
          element: <ImportingContextProvider />,
          children: [
            {
              path: Router.getProjects(),
              element: <ProjectsList />,
            },
            {
              path: Router.getProjectRegisterFile(),
              element: <BulkImportProjects />,
            },
            {
              path: Router.getProjectRegister(':projectId'),
              element: <BulkImportProjectsDetails />,
            },
            {
              path: Router.getProjectRegisterFileDetails(),
              element: <BulkImportProjectsDetails />,
            },
          ],
        },
      ],
    },
  ];
}
