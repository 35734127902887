import { useCallback, useMemo } from 'react';
import { ContractID, ContractV2, ContractV2Response } from '@containers/Subscription/context/types/contractResponseV2';
import { useValueFormatter } from '@shared/hooks';
import { useFetch } from '@shared/hooks/service/useFetch';
import qs from 'query-string';

import {
  CorporateCardContractInformation,
  SAASContractInformation,
  TravelContractInformation,
} from '../../context/types/contractInformation';
import { mapERPColumn, parseContractValue } from './parseContractValue';

export type UseContractInformationParams = {
  allowSelfAcquisition?: boolean;
  skip?: boolean;
};

export function useContractInformationV2(options?: UseContractInformationParams) {
  const { centsToMoney } = useValueFormatter();

  const params = qs.stringify({ allowCreate: options?.allowSelfAcquisition || false });

  const { data, error, isLoading } = useFetch<ContractV2Response>(
    `v2/contracts?${params}`,
    '',
    undefined,
    undefined,
    () => {},
    !options.skip,
  );

  const isSelfAcquisition = useMemo(() => !!error, [error]);

  const findContract = useCallback(
    (planId: ContractID): ContractV2 => {
      const contract = data?.contracts?.find(contract => contract.planIds.includes(planId));

      if (!contract) return null;

      return {
        commercialConditions: contract.commercialConditions,
        planIds: contract.planIds,
        url: contract.url,
      };
    },
    [data?.contracts],
  );

  const createConditionGetter = useCallback(
    (contract: ContractV2) => {
      type GetConditionOptions = {
        isPrice?: boolean;
      };

      return (key: ContractV2['commercialConditions'][number]['id'], options?: GetConditionOptions) => {
        const value = contract.commercialConditions.find(c => c.id === key)?.value;

        const parsedContractValue = parseContractValue(value);

        if (options?.isPrice && typeof parsedContractValue === 'number') {
          return centsToMoney(parsedContractValue);
        }

        return parsedContractValue;
      };
    },
    [centsToMoney],
  );

  const saasServiceContract: SAASContractInformation = useMemo(() => {
    const saasContract = findContract('expense_saas');

    if (isSelfAcquisition) return {};

    if (!saasContract) return null;

    const getCondition = createConditionGetter(saasContract);

    const billingRegistrationNumber =
      getCondition('expense_saas_central_registration_number') || getCondition('expense_saas_billing_company');

    const billingType =
      getCondition('expense_saas_central_billing_opt_in') || getCondition('expense_saas_billing_type');

    const discountPercentage =
      getCondition('expense_saas_discount') || getCondition('expense_saas_discount_percentage');

    const discountPeriodInMonths =
      getCondition('expense_saas_discount_duration') || getCondition('expense_saas_discount_period_in_months');

    return {
      conditions: {
        plan: getCondition('expense_saas_plan'),
        planPackage: getCondition('expense_saas_plan_package'),
        billingRegistrationNumber,
        billingType,
        discountPercentage,
        discountPeriodInMonths,
        gracePeriodInMonths: getCondition('expense_saas_grace_period_in_months'),
        minimumUsersQuantity: getCondition('expense_saas_minimum_users_quantity'),
        pricePerUser: getCondition('expense_saas_price_per_user', { isPrice: true }),
        erpIntegrations: mapERPColumn(getCondition('expense_saas_erp_integration') as string),
      },
    } as unknown as SAASContractInformation;
  }, [createConditionGetter, findContract, isSelfAcquisition]);

  const corporateCardContract: CorporateCardContractInformation = useMemo(() => {
    const cardContract = findContract('expense_corporate');

    if (isSelfAcquisition) return {};

    if (!cardContract) return null;

    const getCondition = createConditionGetter(cardContract);

    const pricePerAdditionalCard =
      getCondition('expense_corporate_card_fee', { isPrice: true }) ||
      getCondition('expense_corporate_card_resend_shipment_fee', { isPrice: true }) ||
      0;

    const additionalCardQuantity = getCondition('expense_corporate_card_additional_cards_quantity');

    const totalAdditionalCardValue = (pricePerAdditionalCard as number) * (additionalCardQuantity as number);

    return {
      conditions: {
        additionalCardQuantity,
        pricePerAdditionalCard,
        totalAdditionalCardValue,
        freeCardQuantity: getCondition('expense_corporate_card_free_cards_quantity'),
      },
    } as CorporateCardContractInformation;
  }, [findContract, createConditionGetter, isSelfAcquisition]);

  const travelContract: TravelContractInformation = useMemo(() => {
    const travelContract = findContract('expense_travel');

    if (isSelfAcquisition) return null;

    if (!travelContract) return null;

    const getCondition = createConditionGetter(travelContract);

    return {
      conditions: {
        onlineBookingValue: getCondition('expense_travel_selfbooking_fee', { isPrice: true }),
        bookingAttendanceValue: getCondition('expense_travel_customer_service_fee', { isPrice: true }),
      },
    } as TravelContractInformation;
  }, [findContract, createConditionGetter, isSelfAcquisition]);

  return {
    contracts: data?.contracts || [],
    isPending: data?.contracts?.some(contract => !contract.url) || isSelfAcquisition,
    saasServiceContract,
    corporateCardContract,
    isSelfAcquisition,
    travelContract,
    isLoading,
    error,
  };
}
