import { useContext, useState } from 'react';
import { IPaymentMethodsSummary } from '@containers/Expenses/context/types/paymentMethods';
import { IUserSelection } from '@containers/Expenses/context/types/users';
import { TagDateFilter, TagFilter, TagSliderRangeFilter } from '@flash-tecnologia/hros-web-ui-v2';
import {
  MAX_RANGE_VALUE,
  MIN_RANGE_VALUE,
  PendingAccountContext,
  STEP_RANGE_VALUE,
} from '@shared/contexts/PendingAccountContext/PendingAccountContext';
import { useValueFormatter } from '@shared/hooks';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export const PendingExpensesTableFilter = (users: IUserSelection[], paymentMethods: IPaymentMethodsSummary[]) => {
  const t = useTranslate('organisms.accountingPending.expensesTable');
  const { filters, setFilters } = useContext(PendingAccountContext);
  const { getValueWithCurrency } = useValueFormatter();
  const [userIds, setUserIds] = useState<number[]>([]);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<string[]>([]);

  function handleTagFilterName() {
    setFilters(prevFilters => ({
      ...prevFilters,
      userIds: userIds,
    }));
  }

  function handleTagFilterContent() {
    setFilters(prevFilters => ({
      ...prevFilters,
      paymentMethods: filteredPaymentMethods,
    }));
  }

  const endOfDay = date => {
    if (!date) return '';
    const newDate = new Date(date);
    newDate.setUTCHours(23, 59, 59, 999);
    return newDate.toISOString();
  };

  function handleDateRangeFilter(dates) {
    const isDifferentDates = dates?.from && dates?.to && dates.from.getTime() !== dates.to.getTime();

    const setUpDate = {
      createdFrom: dates?.from?.toISOString() ?? '',
      createdTo: dates?.to !== undefined && isDifferentDates ? dates.to?.toISOString() : endOfDay(dates?.from),
    };

    setFilters(prevFilters => ({
      ...prevFilters,
      createdFrom: setUpDate.createdFrom,
      createdTo: setUpDate.createdTo,
    }));
  }

  function handleValueRangeFilter(data) {
    const hasValues = data.length > 0;

    setFilters(prevFilters => ({
      ...prevFilters,
      amountFrom: !hasValues ? undefined : data[0],
      amountTo: !hasValues ? undefined : data[1],
    }));
  }

  function renderTableFilters(users) {
    return [
      <TagFilter
        key={'userName'}
        filterLabel={t('filters.userName')}
        hasLeftIcon={false}
        options={
          users?.map(user => ({
            label: user.name,
            value: user.id,
          })) || []
        }
        variant={'secondary'}
        optionIconType={'checkbox'}
        onApply={() => handleTagFilterName()}
        onChange={value => setUserIds(value)}
        onClear={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            userIds: [],
          }));
        }}
      />,

      <TagFilter
        key={'reportContent'}
        hasLeftIcon={false}
        filterLabel={t('filters.paymentMethod')}
        options={
          paymentMethods?.map(paymentMethod => ({
            label: paymentMethod.description,
            value: String(paymentMethod.id),
          })) || []
        }
        variant={'secondary'}
        optionIconType={'checkbox'}
        onApply={() => handleTagFilterContent()}
        onChange={value => setFilteredPaymentMethods(value)}
        onClear={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            paymentMethods: [''],
          }));
        }}
      />,

      <TagDateFilter
        key={'date'}
        hasLeftIcon={false}
        filterLabel={t('filters.creationDate')}
        variant={'secondary'}
        onSubmit={dates => {
          handleDateRangeFilter(dates);
        }}
      />,

      <TagSliderRangeFilter
        key={'sliderRange'}
        variant={'secondary'}
        name={t('filters.value')}
        filterLabel={t('filters.value')}
        step={STEP_RANGE_VALUE}
        min={MIN_RANGE_VALUE}
        max={MAX_RANGE_VALUE}
        valueLabelDisplay={'on'}
        initialValues={[
          filters?.amountFrom ? filters.amountFrom : MIN_RANGE_VALUE,
          filters?.amountTo ? filters.amountTo : MAX_RANGE_VALUE,
        ]}
        fieldMask={value => getValueWithCurrency({ value })}
        onClear={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            amountFrom: undefined,
            amountTo: undefined,
          }));
        }}
        onApply={values => {
          handleValueRangeFilter(values);
        }}
      />,
    ];
  }

  return renderTableFilters(users);
};
