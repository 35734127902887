import { useContext } from 'react';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { EAccountingPendingStatus } from '@containers/Financial/hooks/dataSources/useAccountingPendingExpensesStatus';
import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { CardFilterSkeleton } from '@organisms/CardFilter/CardFilterSkeleton';
import { PendingAccountContext } from '@shared/contexts/PendingAccountContext/PendingAccountContext';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ColorVariant } from 'src/themes/types/Variants';

import * as SC from '../styled';

interface IAccountingPendingCardFilterProps {
  isMain?: boolean;
  isLoading?: boolean;
  quantityItems?: number;
  totalValue?: number;
  status: EAccountingPendingStatus;
  title: string;
}
export const AccountingPendingCardFilter = ({
  isMain,
  isLoading,
  quantityItems = 0,
  totalValue = 0,
  title,
  status,
}: IAccountingPendingCardFilterProps) => {
  const t = useTranslate('organisms.accountingPending.statusCard');
  const { getValueWithCurrency } = useValueFormatter();
  const { filters, setFilters } = useContext(PendingAccountContext);
  const isSelected =
    (filters?.isCorporateCard && status === EAccountingPendingStatus.CORPORATE_CARD_EXPENSES) ||
    (filters?.hasViolation && status === EAccountingPendingStatus.OUT_OF_POLITICS);

  if (isLoading) {
    return <CardFilterSkeleton main={isMain} />;
  }

  function getIconNameForStatus(status: EAccountingPendingStatus) {
    switch (status) {
      case EAccountingPendingStatus.PENDING_ACCOUNTING:
        return 'IconReceipt2';
      case EAccountingPendingStatus.OUT_OF_POLITICS:
        return 'IconAdjustmentsOff';
      case EAccountingPendingStatus.CORPORATE_CARD_EXPENSES:
        return 'IconCreditCard';
    }
  }

  function validateVariantIcon(isSelected: boolean): ColorVariant {
    return isSelected ? 'secondary' : 'neutral';
  }

  function handleSelectTotalizerFilters(status: EAccountingPendingStatus) {
    setFilters(prev => {
      if (status === EAccountingPendingStatus.OUT_OF_POLITICS) {
        return {
          ...prev,
          hasViolation: prev.hasViolation ? undefined : true,
          isCorporateCard: undefined,
        };
      } else if (status === EAccountingPendingStatus.CORPORATE_CARD_EXPENSES) {
        return {
          ...prev,
          isCorporateCard: prev.isCorporateCard ? undefined : true,
          hasViolation: undefined,
        };
      } else {
        return prev;
      }
    });
  }

  return (
    <SC.Container main={isMain} isSelected={isSelected} data-testid="accounting-pending-card-filter">
      <SC.StatusDescriptionContainer isSelected={isSelected}>
        <SC.ReportsStatusTitle>
          <SC.ReportsStatusText variant="body4" weight={600}>
            {title}
          </SC.ReportsStatusText>
        </SC.ReportsStatusTitle>
        <SC.IconFolderContainer isSelected={isSelected}>
          {<ShapeIcon size="xsmall" icon={getIconNameForStatus(status)} variant={validateVariantIcon(isSelected)} />}
        </SC.IconFolderContainer>
      </SC.StatusDescriptionContainer>
      <SC.SubtitleBox>
        <SC.ReportsExpensesText variant="headline8" weight={700} isSelected={isSelected}>
          {`${quantityItems} ${t(`title`)}`}
        </SC.ReportsExpensesText>
        <SC.CircleDivider />
        <SC.ReportsValueText variant="body4" weight={600} isSelected={isSelected}>
          {getValueWithCurrency({ value: totalValue || 0, currencyPrefix: 'R$' })}
        </SC.ReportsValueText>
      </SC.SubtitleBox>
      {status !== EAccountingPendingStatus.PENDING_ACCOUNTING && (
        <>
          <LinkButton
            variant="default"
            data-testid="filter-button"
            onClick={() => handleSelectTotalizerFilters(status)}
            aria-label={`${isSelected} ? t("filterButton.removeFilter") : t("filterButton.addFilter")}`}>
            {isSelected ? (
              <>
                {t('filterButton.removeFilter')}
                <Icons size={12} name="IconFilterOff" fill="transparent" />
              </>
            ) : (
              <>
                {t('filterButton.addFilter')}
                <Icons size={12} name="IconFilter" fill="transparent" />
              </>
            )}
          </LinkButton>
        </>
      )}
    </SC.Container>
  );
};
