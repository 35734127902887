import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { CompanyPayables } from '@flash-hros/company-payables';
import { useCheckCompanyPayablesEnable } from '@shared/hooks/permissions/companyPayables/useCheckCompanyPayablesEnable';

const { PayablesConfigurationTab } = CompanyPayables;

export function PayablesTabItems() {
  const isCheckCompanyPayablesEnable = useCheckCompanyPayablesEnable();

  return (
    isCheckCompanyPayablesEnable && (
      <ModuleErrorBoundary
        moduleName="Contas a Pagar"
        title="Erro ao tentar carregar tabs de Despesas com o Fornecedor">
        <PayablesConfigurationTab />
      </ModuleErrorBoundary>
    )
  );
}
