import { TPendingExpenseByUser } from '@containers/Financial/context/types/users';
import { IFilters } from '@shared/contexts/PendingAccountContext/PendingAccountContext';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import qs from 'query-string';

type TFetchResponse = {
  expenses: TPendingExpenseByUser[];
  currentPage: number;
  pages: number;
  limit: number;
  total: number;
};

type TExpenseDetailedByEmployee = {
  shouldFetch?: boolean;
  filters: Pick<IFilters, 'userIds'> & {
    search?: string;
    userId: number;
    page?: number;
    pageLimit?: number;
  };
};

export function useExpensePendingByUser({ filters, shouldFetch = false }: TExpenseDetailedByEmployee) {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });
  const params = qs.stringify(filters, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
  });
  const { data, error, isLoading, mutate } = useFetch<TFetchResponse>(
    filters.userId ? `v1/analytics/expense/user/expense-detailed?${params}` : null,
    undefined,
    undefined,
    undefined,
    onError,
    shouldFetch,
  );

  return {
    response: {
      expenses: data?.expenses || [],
      currentPage: data?.currentPage,
      pages: data?.pages,
      limit: data?.limit,
      total: data?.total,
    },
    isLoading: isLoading,
    error,
    refetch: () => mutate(),
  };
}
