import { useContext, useState } from 'react';
import { DelegateUsers } from '@containers/Expenses/components/organisms/ExpenseActions/DelegateUsers/DelegateUsers';
import { PolicyDrawer } from '@containers/Policies/componentes/organisms/PolicyDrawer/PolicyDrawer';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { MenuPageAction, OptionProps } from '@molecules/PageMenuAction';
import { baseUrl } from '@shared/constant';
import { DelegateContext } from '@shared/contexts/DelegateContext/DelegateContext';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { useCheckCompanyPayablesEnable } from '@shared/hooks/permissions/companyPayables/useCheckCompanyPayablesEnable';
import { useDelegatePermissions } from '@shared/hooks/permissions/systemPermissions/useDelegatePermissions';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

export function ExpensePageActions() {
  const { userDelegateContext, setUserDelegate } = useContext(DelegateContext);
  const navigate = useNavigate();
  const t = useTranslate('pages.sharedExpenses');
  const { isAdmin } = useContext(SessionContext);
  const [openUsersDelegation, setOpenUsersDelegation] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { checkPlatformPermission } = usePlatformPermissions();
  const createVendorEnabled = checkPlatformPermission(EAuthZPermission.VENDOR_CREATE);
  const delegatePermissions = useDelegatePermissions();
  const allowedDelegationConfiguration = delegatePermissions.settingsApproval || delegatePermissions.settingsExpenses;
  const isCheckCompanyPayablesEnable = useCheckCompanyPayablesEnable();
  const actionOptions = getPageActionOptions();
  const [openPolicy, setOpenPolicy] = useState(false);

  function getPageActionOptions() {
    const options: OptionProps[] = [];

    options.push({
      dropDownProps: { description: t('policy'), icon: 'IconShieldCheck' },
      onClick: () => {
        setPopoverOpen(false);
        setOpenPolicy(true);
      },
    });

    if (isAdmin) {
      options.push({
        dropDownProps: { description: t('goToConfiguration'), icon: 'IconSettings' },
        onClick: () => {
          navigate('/expenseManagement/customizations');
        },
      });
    } else if (delegatePermissions.settingsExpenses) {
      options.push({
        dropDownProps: { description: t('goToDelegationSettings'), icon: 'IconSettings' },
        onClick: () => {
          navigate(`${baseUrl}/delegationAction`);
        },
      });
    }

    if (createVendorEnabled && isCheckCompanyPayablesEnable) {
      options.push({
        dropDownProps: { description: t('addVendor'), icon: 'IconBox' },
        onClick: () => {
          navigate('/expense/company-payables/vendors/form');
        },
      });
    }

    if (delegatePermissions.view)
      if (userDelegateContext?.id) {
        options.push({
          dropDownProps: {
            description: t('backToMyProfile'),
            icon: 'IconArrowBack',
          },
          onClick: () => {
            onClosePopovers();
            popoverOpen && setUserDelegate(null);
          },
          isPopover: true,
          key: 'backToMyProfile',
        });
      } else {
        options.push({
          dropDownProps: {
            description: t('delegateProfiles'),
            icon: !isAdmin && !options?.length && !allowedDelegationConfiguration ? 'IconUserCheck' : 'IconUserSearch',
          },
          onClick: () => {
            setOpenUsersDelegation(true);
            setPopoverOpen(true);
          },
          isPopover: true,
          key: 'userDelegate',
          iconRight: 'IconChevronRight',
        });
      }

    return options;
  }

  function onGoBackClick() {
    setOpenUsersDelegation(false);
    setPopoverOpen(true);
  }

  function onClosePopovers() {
    setPopoverOpen(false);
    setOpenUsersDelegation(false);
  }

  function getCustomIcons(): IconTypes {
    return isAdmin &&
      actionOptions?.length === 1 &&
      (actionOptions?.[0]?.key === 'userDelegate' || actionOptions?.[0]?.key === 'backToMyProfile') &&
      allowedDelegationConfiguration
      ? 'IconUserCheck'
      : null;
  }

  return (
    <SC.Container>
      <MenuPageAction
        options={actionOptions}
        onClose={onClosePopovers}
        open={popoverOpen}
        onOpen={() => setPopoverOpen(true)}
        customIcon={getCustomIcons()}>
        {openUsersDelegation && (
          <DelegateUsers
            showBackButton={actionOptions?.length > 1}
            onGoBackClick={onGoBackClick}
            onClose={onClosePopovers}
          />
        )}
      </MenuPageAction>
      <PolicyDrawer openDrawer={openPolicy} setOpenDrawer={setOpenPolicy} />
    </SC.Container>
  );
}
