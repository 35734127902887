import { createContext } from 'react';

import { IConstractSign } from '../hooks/useSignContract';
import {
  CorporateCardContractInformation,
  SAASContractInformation,
  TravelContractInformation,
} from './types/contractInformation';
import { ContractV2 } from './types/contractResponseV2';

type SubscriptionContextType = {
  /**
   * All existing contracts
   */
  contracts: ContractV2[];

  /**
   * Contract information
   */
  saasServiceContract: SAASContractInformation | undefined;

  /**
   * Corporate card information
   */
  corporateCardContract: CorporateCardContractInformation | undefined;

  /**
   * Travel contract
   */
  travelContract: TravelContractInformation | undefined;

  /**
   * If contract is being signed
   */
  isSigning: boolean;

  /**
   * On contract creation
   */
  onContractCreationRequest(): void;

  /**
   * On sign requested
   */
  onSignContract(): Promise<IConstractSign>;
};

export const SubscriptionContext = createContext<SubscriptionContextType>({} as SubscriptionContextType);
