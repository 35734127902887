import { useEffect, useState } from 'react';
import { useSignContract } from '@containers/Subscription/hooks/useSignContract';
import { useContractInformationV2 } from '@containers/Subscription/hooks/v2/useContractInformationV2';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';
import { useSearchParams } from 'react-router-dom';

export function useContracts() {
  const [searchParams] = useSearchParams();
  const sourceModule = searchParams.get('module');

  useTrackingScreen('expenses_contract_screen', { module: sourceModule });

  const { signExpenseContracts, isLoading: isSigning } = useSignContract(sourceModule);

  const [shouldCreateContract, setShouldCreateContract] = useState(!!sourceModule);
  const [isFullPageLoaderEnabled, setIsFullPageLoaderEnabled] = useState(true);

  const contractV2 = useContractInformationV2({
    allowSelfAcquisition: shouldCreateContract || !!sourceModule,
  });

  const { corporateCardContract, saasServiceContract, travelContract, isLoading } = contractV2;

  function onContractCreationRequest() {
    if (contractV2.isPending) {
      setShouldCreateContract(true);
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setIsFullPageLoaderEnabled(false);
    }
  }, [isLoading]);

  return {
    contracts: contractV2.contracts,
    signExpenseContracts,
    isSigning,
    saasServiceContract,
    corporateCardContract,
    travelContract,
    onContractCreationRequest,
    isCreatingContracts: contractV2.isPending,
    isLoading: isLoading,
    isFullPageLoaderEnabled,
    isSelfAcquisition: contractV2.isSelfAcquisition,
  };
}
