import { useContext } from 'react';
import { useSelectedTab } from '@containers/Customizations/hooks/useSelectedTab';
import { Tab } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useUnleashPermissions } from '@shared/hooks/permissions/unleash/useUnleashPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';

import CorporateCardCustomizationsTab from './components/CorporateCardCustomizationsTab';
import { ExpenseCustomizationTabItem } from './ExpenseCustomizationTabItem';
import { PayablesTabItems } from './PayAblesTabItems';
import * as SC from './styled';
import { TravelTabItem } from './TravelTabItem';

export enum ETabCustomization {
  GENERAL_SETTINGS = 'generalSettings',
  FORMS = 'forms',
  REPORT = 'reports',
  CATEGORIES = 'categories',
  POLICIES = 'policies',
  APPROVALS = 'approvals',
  ADVANCES = 'advances',
  DELEGATION = 'delegation',
}

type TTab = {
  id: 'expense' | 'supplier' | 'corporate-card' | 'travel';
  label: string;
  component: React.ReactNode;
};

export const CustomizationsPreview = () => {
  const { t } = useTranslation();
  const { checkPlatformPermission } = usePlatformPermissions();
  const corporateCardCustomizationsTab = CorporateCardCustomizationsTab();
  const showPayablesTab = checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_PAYABLES_VIEW);
  const { isDisabledExpense } = useContext(SessionContext);
  const { permissions } = useUnleashPermissions();
  const tabs: TTab[] = [];

  if (!isDisabledExpense) {
    tabs.push({
      id: 'expense',
      component: <ExpenseCustomizationTabItem />,
      label: t('organisms.customizationsPreview.tabTitle.expense'),
    });

    showPayablesTab &&
      tabs.push({
        id: 'supplier',
        component: <PayablesTabItems />,
        label: t('organisms.customizationsPreview.tabTitle.companyPayables'),
      });

    permissions.enabledTravelPreferences &&
      tabs.push({
        id: 'travel',
        component: <TravelTabItem />,
        label: t('organisms.customizationsPreview.tabTitle.corporateTravel'),
      });
  }

  if (corporateCardCustomizationsTab.show) {
    tabs.push({ ...corporateCardCustomizationsTab, id: 'corporate-card' });
  }

  const { selectedTabIndex, onTabChange } = useSelectedTab(tabs);

  return (
    <SC.Container>
      <Tab variant="primary" selected={selectedTabIndex} onTabChanged={onTabChange} tabItens={tabs} />
    </SC.Container>
  );
};
