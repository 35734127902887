import { useContext, useState } from 'react';
import { SubscriptionContext } from '@containers/Subscription/context';
import { useContractsHelper } from '@containers/Subscription/hooks/useContractsHelper';
import { IConstractSign } from '@containers/Subscription/hooks/useSignContract';
import { expensesCustomizationsBaseURL } from '@shared/constant';

type Props = {
  onSignSuccess?: () => void;
};

export function useCompletionRegistration(props?: Props) {
  const { onSignContract, isSigning } = useContext(SubscriptionContext);
  const [constractsSignIds, setConstractsSignIds] = useState<IConstractSign['contracts']>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  const { isLoading } = useContractsHelper(shouldFetch);

  async function handleSignContract() {
    try {
      const response = await onSignContract();

      response.contracts && setConstractsSignIds(response.contracts);

      setShouldFetch(true);
      setShowSuccessModal(true);

      props?.onSignSuccess?.();
    } catch (err) {
      setShowSuccessModal(false);
    }
  }

  function onFinishSubscription() {
    window.open(expensesCustomizationsBaseURL, '_top');
  }

  return {
    isLoadingAcceptance: isSigning || isLoading,
    showSuccessModal,
    constractsSignIds,
    handleSignContract,
    onFinishSubscription,
  };
}
