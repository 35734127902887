import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { CompanyPayables } from '@flash-hros/company-payables';
import { useCheckCompanyPayablesEnable } from '@shared/hooks/permissions/companyPayables/useCheckCompanyPayablesEnable';

const { ApprovalsTab } = CompanyPayables;

export function PayablesApprovalTab() {
  const isCheckCompanyPayablesEnable = useCheckCompanyPayablesEnable();

  return (
    isCheckCompanyPayablesEnable && (
      <ModuleErrorBoundary moduleName="Contas a Pagar" title="Erro ao tentar carregar tab de Despesas com o Fornecedor">
        <ApprovalsTab />
      </ModuleErrorBoundary>
    )
  );
}
