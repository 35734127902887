import { useEffect, useMemo, useState } from 'react';
import { JustificationModalReportReproval } from '@containers/Financial/components/organisms/Reports/JustificationModalReportReproval';
import { ReportSummariesFilters } from '@containers/Reports/components/organisms/ReportSummariesFilters';
import { EReportStatus, EReportViewType, IReportSummariesFilter } from '@containers/Reports/context/types';
import { useReportsSummaries } from '@containers/Reports/hooks/useReportsSummaries';
import { usePagination } from '@molecules/GridPagination/kooks/usePagination';
import { useReportStatusesPermissions } from '@shared/hooks/permissions/systemPermissions/useReportStatusesPemission';

import { useApprovalsReportsActionManager } from '../ApprovalsPreview/hooks/useApprovalReportsActionManager';
import { ApprovalsReportsGrid } from '../ApprovalsReportsGrid';
import { useApprovalReportSelectionRows } from '../ApprovalsReportsGrid/hooks/useApprovalReportSelectionRows';
import { ConfirmReportApprovalModal } from '../ConfirmReportApprovalModal/ConfirmReportApprovalModal';
import * as SC from './styled';

export interface ApprovalReportGridFiltersProps {
  setReportsQuantity(value: number): void;
}

export const ReportsTabGrid = ({ setReportsQuantity }: ApprovalReportGridFiltersProps) => {
  const pagination = usePagination({ currentPage: 1, itemsPerPageShow: 20 });
  const [selectedFilters, setSelectedFilters] = useState<IReportSummariesFilter>({
    search: '',
    status: [EReportStatus.SUBMITTED],
    awaitingImmediateApproverDecision: true,
  });
  const {
    response: { reports, total },
    isLoading,
    refetch,
  } = useReportsSummaries({
    filters: {
      page: pagination.selectedPage,
      limit: pagination.rowsPerPage,
      ...selectedFilters,
    },
    viewType: EReportViewType.APPROVAL,
  });
  const selectionRow = useApprovalReportSelectionRows({ reports });
  const {
    isLoadingMutate,
    reportsSelected,
    justificationIsOpen,
    approveConfirmIsOpen,
    exportingItems,
    onViewClick,
    onReproveClick,
    onApproveClick,
    onClosePopup,
    onActionJustificationPopup,
    onActionApproveConfirmPopup,
    onExportPdfUrlByCategoryClick,
    onExportPdfUrlClick,
  } = useApprovalsReportsActionManager({ unSelectAllClick: selectionRow.unSelectAllClick });
  const reportStatus = useReportStatusesPermissions();

  useEffect(() => {
    setReportsQuantity(reports?.length);
  }, [reports]);

  useMemo(() => {
    !isLoadingMutate && refetch();
  }, [isLoadingMutate]);

  const onPage = (pageSelected: number) => {
    selectionRow.unSelectAllClick();
    pagination.onPageClick(pageSelected);
  };

  return (
    <SC.Container>
      <ReportSummariesFilters
        isLoading={isLoadingMutate}
        onFiltersChange={data => {
          selectionRow.unSelectAllClick();
          setSelectedFilters(data);
        }}
        optionsSelectStatus={reportStatus}
        viewType={EReportViewType.APPROVAL}
        selectedFilters={selectedFilters}
      />
      <ApprovalsReportsGrid
        reports={reports}
        isLoading={isLoading || isLoadingMutate}
        selectedItems={reportsSelected}
        isSelectedAll={reportsSelected?.length == total}
        onOrderByClick={() => null}
        onViewClick={onViewClick}
        onReproveClick={onReproveClick}
        onApproveClick={onApproveClick}
        onExportPdfClick={onExportPdfUrlClick}
        onExportPdfByCategoryClick={onExportPdfUrlByCategoryClick}
        exportingItems={exportingItems}
        {...selectionRow}
        pagination={{ ...pagination, totalItems: total, onPageClick: onPage }}
      />
      <JustificationModalReportReproval
        isOpen={justificationIsOpen}
        isLoading={isLoadingMutate}
        onActionClick={justification => onActionJustificationPopup(justification)}
        onCloseClick={onClosePopup}
        reportsNumber={reportsSelected?.length}
      />
      <SC.ModalContainer>
        <ConfirmReportApprovalModal
          isOpen={approveConfirmIsOpen}
          isLoading={isLoadingMutate}
          onActionClick={() => onActionApproveConfirmPopup()}
          onCloseClick={onClosePopup}
          reportsNumber={reportsSelected?.length}
        />
      </SC.ModalContainer>
    </SC.Container>
  );
};
