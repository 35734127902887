import { ApprovalType } from '@containers/Customizations/context/types/approvals';
import { useReportApprovalSettings } from '@containers/Financial/hooks/dataSources/useReportApprovalSettings';
import { IReport, ReportUser } from '@containers/Reports/context/types';
import { useApproversReportById } from '@containers/Reports/hooks/useApproversReportById';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ApproversByReport } from '../ApproversByReport/ApproversByReport';
import { MainSummaryData } from '../ReportInfosSummary/MainSummaryData/MainSummaryData';

interface IProps {
  reportId: IReport['id'];
  approver: ReportUser;
}

export function ReportApprovers({ reportId, approver }: IProps) {
  const t = useTranslate('report');
  const { approvers, isLoading } = useApproversReportById(reportId);
  const { approval } = useReportApprovalSettings();

  if (isLoading) {
    return (
      <div>
        <Skeleton variant="rounded" width={98} height={21} style={{ marginBottom: '5px' }} />
        <Skeleton variant="rounded" width={90} height={18} />
      </div>
    );
  }

  if (approval.approvalType == ApprovalType.CUSTOMIZED_APPROVAL) {
    return approvers.length ? <ApproversByReport approvers={approvers} /> : <> </>;
  } else {
    return <MainSummaryData title={approver?.name || t('uninformed')} caption={t('approver')} />;
  }
}
