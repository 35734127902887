import { useEffect } from 'react';

import { useTracking } from './useTracking';

export type ScreenEventName =
  | 'expenses_contract_screen'
  | 'expense_list_screen'
  | 'expense_form_screen'
  | 'expense_details_screen'
  | 'report_list_screen'
  | 'report_details_screen'
  | 'financial_reports_screen'
  | 'financial_report_detail_screen'
  | 'customizations_screen'
  | 'financial_reports_advance_screen';

export const useTrackingScreen = (name: ScreenEventName, properties?: Record<string, string>) => {
  const trackingEvent = useTracking();

  useEffect(() => {
    trackingEvent(name, properties);
  }, []);
};
