import {
  EAccountingPendingStatus,
  TUsersStatusSummary,
} from '@containers/Financial/hooks/dataSources/useAccountingPendingExpensesStatus';
import { useTranslation } from '@locale/Translator';
import { CardFiltersGroup } from '@organisms/CardFiltersGroup';

import { AccountingPendingCardFilter } from './AccountingPendingCardFilter/AccountingPendingCardFilter';

interface IAccountingPendingCardGroupProps {
  totalizers: TUsersStatusSummary | unknown;
  isLoading?: boolean;
  selectedStatus?: EAccountingPendingStatus;
}

export const AccountingPendingCardGroup = ({ totalizers, isLoading }: IAccountingPendingCardGroupProps) => {
  const { t } = useTranslation();
  function getCards() {
    return Object.values(EAccountingPendingStatus)?.map((currentStatus, key) => (
      <AccountingPendingCardFilter
        key={key}
        isMain={true}
        isLoading={isLoading}
        quantityItems={totalizers?.[currentStatus]?.count}
        totalValue={totalizers?.[currentStatus]?.totalValue}
        status={currentStatus}
        title={t(`organisms.accountingPending.statusCard.${currentStatus}`)}
      />
    ));
  }

  return <CardFiltersGroup numberOfColumns={3}>{getCards()}</CardFiltersGroup>;
};
