import { expensesBaseUrl } from '@shared/constant';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

import { useCompanyAccessConfiguration } from './hooks/useCompanyAccessConfiguration';

export const RoutesGuardSubscription = ({ children }: { children?: JSX.Element }) => {
  const { termsSigned } = useCompanyAccessConfiguration();
  const [params] = useSearchParams();

  if (termsSigned && !params.has('module')) {
    return <Navigate to={expensesBaseUrl} />;
  }

  return children ? children : <Outlet />;
};
