import { useCompletionRegistration } from '@containers/Subscription/components/atoms/AcceptSubscriptionCheckbox/hooks/useCompletionRegistration';
import { CompletionRegistrationModal } from '@containers/Subscription/components/molecules/CompletionRegistrationModal/CompletionRegistrationModal';
import { Button, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Box } from '@mui/material';
import { useIsMobile } from '@shared/hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface Props {
  confirmIsDisabled: boolean;
}

export const ActionsFooterPage = ({ confirmIsDisabled }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoadingAcceptance, showSuccessModal, constractsSignIds, onFinishSubscription, handleSignContract } =
    useCompletionRegistration();

  const isMobile = useIsMobile();

  return (
    <>
      <SC.Container>
        <div>
          <LinkButton variant="default" onClick={() => navigate(-1)}>
            {t('pages.subscriptionConfirmation.actionsPage.cancel')}
          </LinkButton>
        </div>
        <SC.ActionsButtonsFooterContainer>
          {!isMobile && (
            <Box mr={2}>
              <Button variant="secondary" size="medium" onClick={() => navigate(-1)}>
                <SC.ButtonContent>{t('pages.subscriptionConfirmation.actionsPage.toGoBack')}</SC.ButtonContent>
              </Button>
            </Box>
          )}
          <div>
            <Tooltip
              arrow
              title={confirmIsDisabled ? t('pages.subscriptionConfirmation.tooltip.confirm') : ''}
              placement="top-start">
              <div>
                <Button
                  variant="primary"
                  size="medium"
                  type="submit"
                  loading={isLoadingAcceptance}
                  onClick={() => handleSignContract()}
                  disabled={confirmIsDisabled}>
                  {t('pages.subscriptionConfirmation.actionsPage.confirm')}
                </Button>
              </div>
            </Tooltip>
          </div>
        </SC.ActionsButtonsFooterContainer>
      </SC.Container>
      <SC.ContainerModals>
        <CompletionRegistrationModal
          open={showSuccessModal}
          constractSignIds={constractsSignIds}
          onAction={onFinishSubscription}
        />
      </SC.ContainerModals>
    </>
  );
};
