import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

const URL_USER_PENDING_EXPENSES_STATUS = 'v1/analytics/expense/user/totalizer';

interface IAccountingPendingExpensesStatus {
  shouldFetch?: boolean;
}

export interface ISummaryQuantityDetails {
  count: number;
  totalValue: number;
}

export type TUsersStatusSummary = {
  pendingAccounting: ISummaryQuantityDetails;
  outOfPolitics: ISummaryQuantityDetails;
  corporateCardExpenses: ISummaryQuantityDetails;
};

interface IUseAccountingPendingExpensesStatusReturn {
  data: TUsersStatusSummary;
  isLoading: boolean;
  error: unknown;
  refetch: () => void;
}

export enum EAccountingPendingStatus {
  PENDING_ACCOUNTING = 'pendingAccounting',
  OUT_OF_POLITICS = 'outOfPolitics',
  CORPORATE_CARD_EXPENSES = 'corporateCardExpenses',
}

export function useAccountingPendingExpensesStatus({
  shouldFetch = true,
}: IAccountingPendingExpensesStatus): IUseAccountingPendingExpensesStatusReturn {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading, mutate } = useFetch<TUsersStatusSummary>(
    `${URL_USER_PENDING_EXPENSES_STATUS}`,
    undefined,
    undefined,
    undefined,
    onError,
    shouldFetch,
  );

  return {
    data: data,
    isLoading: isLoading,
    error,
    refetch: () => mutate(),
  };
}
