import MandatoryInfo from '@atoms/MandatoryInfo/MandatoryInfo';
import { useAreas } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useAreas';
import { CostCenterForm } from '@containers/CostCenters/validators/CostCenterFormSchema';
import { BoxAdornment, TextField, Toggle, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Autocomplete, Box, Grid } from '@mui/material';
import { EFieldType, FieldFactory } from '@organisms/FieldFactory';
import { useEmployeesSelectionList } from '@shared/hooks/employees/useEmployeesSelectionList';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldRegister } from './FieldRegister/FieldRegister';
import * as SC from './styled';

type Props = {
  isLoading: boolean;
};

export const CostCenterRegisterGeneralData = ({ isLoading }: Props) => {
  const t = useTranslate('pages.costCenterRegister');
  const { control } = useFormContext<CostCenterForm>();
  const { employees: users, isLoading: isLoadingUsers } = useEmployeesSelectionList();
  const isLoadingFields = isLoading || isLoadingUsers;

  const { areas, isLoading: isLoadingAreas } = useAreas();

  return (
    <SC.Container>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography variant="headline8">{t('sections.generalData')}</Typography>
        <MandatoryInfo />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoadingFields}>
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={`${t('fields.costCenterName')} *`}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoadingFields}>
            <Controller
              control={control}
              name="accountable"
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={field.value || null}
                  options={users.map(user => String(user.id))}
                  getOptionLabel={id => users.find(user => String(user.id) === id)?.name ?? ''}
                  fullWidth
                  onChange={(_, newValue) => field.onChange(String(newValue))}
                  renderInput={props => (
                    <TextField
                      {...props}
                      size="small"
                      label={t('fields.responsible')}
                      fullWidth
                      disabled={isLoadingUsers}
                    />
                  )}
                  size="small"
                />
              )}
            />
          </FieldRegister>
        </Grid>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoadingFields}>
            <Controller
              control={control}
              name="departmentIds"
              render={({ field }) => {
                return (
                  <FieldFactory
                    isLoading={isLoadingAreas}
                    fieldType={EFieldType.OPTIONS}
                    label={t('fields.department')}
                    {...field}
                    onChange={data => field.onChange(data)}
                    allowsEditing
                    value={field.value ?? []}
                    options={areas?.map(area => ({ value: area.id, label: area.name }))}
                    isSelectMultiple
                  />
                );
              }}
            />
          </FieldRegister>
        </Grid>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoadingFields}>
            <Controller
              control={control}
              name="externalCode"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={`${t('fields.code')} *`}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={
                    <>
                      {fieldState.invalid ? (
                        <>
                          {fieldState.error?.message}
                          <br />
                          {t('fields.codeHelperText')}
                        </>
                      ) : (
                        t('fields.codeHelperText')
                      )}
                    </>
                  }
                />
              )}
            />
          </FieldRegister>
        </Grid>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoadingFields}>
            <Controller
              control={control}
              name="active"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <BoxAdornment
                  title={t('fields.activeCostCenter.title')}
                  description={t('fields.activeCostCenter.description')}
                  leftAdornment={<Toggle checked={value} onChange={(_, newValue) => onChange(newValue)} />}
                  onClick={() => onChange(!value)}
                  style={{ width: '100%', textAlign: 'left' }}
                />
              )}
            />
          </FieldRegister>
        </Grid>
      </Grid>
    </SC.Container>
  );
};
