import { useEffect, useMemo, useState } from 'react';
import { EExpenseType, IExpenseInput, ISummaryReport, ReceiptFile } from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { useExpenseFormFields } from '@containers/Expenses/hooks/useExpenseFormFields';
import { useOCR } from '@containers/Expenses/hooks/useOCR';
import { useRatePerKm } from '@containers/Expenses/hooks/useRatePerKm';
import { IReport } from '@containers/Reports/context/types';
import { RegistrationFormContainerWithReport } from '@organisms/RegistrationFormContainerWithReport';
import { useDateFormatter } from '@shared/hooks';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useCheckIsKmType } from '@shared/hooks/useCheckIsKmType';
import { useSelectsOptions } from '@shared/hooks/useSelectsOptions';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ReceiptsImages } from '../../molecules/ReceiptsImages';
import { ESizeVariant } from '../../molecules/ReceiptsImages/variants';
import { OdometerFields } from '../OdometerFields/OdometerFields';
import { WaypointFields } from '../WaypointFields/WaypointFields';
import { ExpenseFormFields } from './ExpenseFormFields';
import { useLinkedReport } from './hooks/useLinkedReport';
import * as SC from './styled';

export interface IExpenseLinkedReport {
  id?: number;
  name?: string;
}
export interface IExpenseRegisterFormProps {
  expense: IExpense;
  expenseIsLoading: boolean;
  expenseType: EExpenseType;
  onSubmitClick(data: IExpenseInput): void;
  onSelectReportClick(reportSelected: ISummaryReport | IReport): void;
}

export const ExpenseRegisterForm = ({
  expense,
  expenseIsLoading,
  expenseType,
  onSubmitClick,
  onSelectReportClick,
}: IExpenseRegisterFormProps) => {
  const { checkLicenseFeature } = useLicenseFeatures();
  const { expenseId } = useParams<{ expenseId: string }>();
  const { fields, isLoading: fieldsIsLoading, error: fieldsError } = useExpenseFormFields();
  const [reportSelected, setReportSelected] = useState(null);
  const { control, watch, setValue, getValues } = useFormContext<IExpenseInput>();
  const { getFormatDateUTC } = useDateFormatter();
  const {
    categoryOptions,
    currenciesOptions,
    clientsOptions,
    projectsOptions,
    paymentMethodsOptions,
    costCentersOptions,
    isLoadingOptions,
  } = useSelectsOptions({
    hasCategories: fields?.category?.active ?? false,
    hasClients: fields?.client?.active ?? false,
    hasCostCenters: fields?.costCenter?.active ?? false,
    hasCurrencies: fields?.currency?.active ?? false,
    hasPaymentMethods: fields?.paymentType?.active ?? false,
    hasProjects: fields?.project?.active ?? false,
    clientId: getValueForOptions('clientId'),
    expenseDate: getValueForOptions('expenseDate'),
    projectId: getValueForOptions('projectId'),
    expenseType,
    isCardExpense: !!expense?.cardId,
  });
  const report = useLinkedReport(reportSelected || expense?.report);
  const { isKmType } = useCheckIsKmType(expenseType);
  const [currentDate] = useState<string>(String(getFormatDateUTC(new Date())));
  const { ratePerKm: rateKm, isLoading: ratePerKmIsLoading } = useRatePerKm({
    date: expense?.createdAt || currentDate,
    projectId: getValues('projectId'),
    skip: !getRatePerKmPermission() && !isKmType,
  });
  const currentRateKm = useMemo(() => {
    if (isKmType && !ratePerKmIsLoading) {
      const keyValue = expenseType === EExpenseType.ODOMETER ? 'odometer' : 'route';
      const _distance = watch(`${keyValue}.distance`);

      setValue(`${keyValue}.ratePerKm`, rateKm || expense?.route?.ratePerKm || 0);
      setValue('value', (rateKm || expense?.route?.ratePerKm || 0) * _distance);
    }
    return rateKm;
  }, [rateKm, expenseType]);
  const { createOCRExpense, loading: ocrIsLoading } = useOCR();

  function getRatePerKmPermission() {
    return isKmType && checkLicenseFeature('BASIC_MILEAGE');
  }

  function getValueForOptions(key: 'clientId' | 'expenseDate' | 'projectId') {
    return checkLicenseFeature('ADVANCED_EXPENSE') ? (watch(key) && String(watch(key))) || null : null;
  }

  function onSelectedReport(selectedReport: ISummaryReport | IReport) {
    setReportSelected(selectedReport);
    onSelectReportClick(selectedReport);
  }

  function onOCRChange(data: ReceiptFile[]) {
    const createAllowedOcr = !expense?.id && data.length && !getValues('id');
    createAllowedOcr && createOCRExpense(data, report?.id || expense?.report?.id || null);
  }

  useEffect(() => {
    if (expense?.id && !isLoadingOptions) {
      const existingCategoryInList = categoryOptions?.options?.some(
        category => String(category.value) === String(expense?.category?.id),
      );

      !existingCategoryInList && setValue('categoryId', null);
    }
  }, [categoryOptions?.options, expense?.id, isLoadingOptions]);

  useEffect(() => {
    if (!projectsOptions?.isLoading && projectsOptions.options && checkLicenseFeature('ADVANCED_EXPENSE')) {
      if (
        !projectsOptions?.options?.some(
          project =>
            String(project.value) === String(expense?.project?.id) ||
            String(getValues('projectId')) === String(expense?.project?.id) ||
            String(getValues('projectId')) === String(project.value),
        )
      ) {
        setValue('projectId', null);
      }
    }
  }, [projectsOptions?.options?.toString(), expense?.id]);

  useEffect(() => {
    if (report?.id && report?.id !== getValues('reportId')) setValue('reportId', report.id);
  }, [report?.id]);

  return (
    <RegistrationFormContainerWithReport
      isLoading={fieldsIsLoading || expenseIsLoading || isLoadingOptions}
      report={report}
      targetId={parseInt(expenseId)}
      target="EXPENSE"
      onSelectReportClick={data => onSelectedReport(data)}>
      <SC.FieldsContent isMileage={isKmType}>
        <SC.FieldsContainer>
          {checkLicenseFeature('BASIC_MILEAGE') &&
            (expenseType === EExpenseType.ORIGIN_DESTINATION || expenseType === EExpenseType.GPS) && (
              <SC.KmContainer>
                <WaypointFields
                  route={expense?.route}
                  isLoading={fieldsIsLoading || expenseIsLoading || isLoadingOptions}
                  ratePerKm={currentRateKm}
                  ratePerKmIsLoading={ratePerKmIsLoading}
                  expenseType={expenseType}
                />
              </SC.KmContainer>
            )}

          {checkLicenseFeature('BASIC_MILEAGE') && expenseType === EExpenseType.ODOMETER && (
            <SC.KmContainer>
              <OdometerFields
                odometer={expense?.odometer}
                ratePerKm={currentRateKm}
                isLoading={fieldsIsLoading || expenseIsLoading}
                ratePerKmIsLoading={ratePerKmIsLoading}
              />
            </SC.KmContainer>
          )}

          <ExpenseFormFields
            expenseType={expenseType}
            onSubmitClick={onSubmitClick}
            fields={fields}
            expense={expense}
            isLoading={fieldsIsLoading || expenseIsLoading || isLoadingOptions}
            ocrIsLoading={ocrIsLoading}
            error={fieldsError}
            listFieldsOptions={{
              category: categoryOptions,
              currency: currenciesOptions,
              client: clientsOptions,
              project: projectsOptions,
              paymentType: paymentMethodsOptions,
              costCenter: costCentersOptions,
            }}
          />
        </SC.FieldsContainer>
        {!isKmType && (
          <SC.ReceiptsContainer>
            <Controller
              control={control}
              name="attachments"
              render={({ field }) => (
                <ReceiptsImages
                  receiptsImages={field.value || []}
                  onChange={data => {
                    field.onChange(data);
                    onOCRChange(data);
                  }}
                  isLoading={fieldsIsLoading || expenseIsLoading || isLoadingOptions}
                  size={ESizeVariant.medium}
                  isDisabled={ocrIsLoading}
                />
              )}
            />
          </SC.ReceiptsContainer>
        )}
      </SC.FieldsContent>
    </RegistrationFormContainerWithReport>
  );
};
