import { t } from 'i18next';
import * as y from 'yup';

export const costCenterFormSchema = y.object({
  name: y.string().required().label(t('pages.costCenterRegister.fields.costCenterName')),
  active: y.boolean().default(false),
  externalCode: y.string().required().label(t('pages.costCenterRegister.fields.code')),
  accountable: y.string().nullable().label(t('pages.costCenterRegister.fields.responsible')),
  departmentIds: y.array().of(y.string()).label(t('pages.costCenterRegister.fields.department')).nullable(),
  userIds: y.array().of(y.string()).label(t('pages.costCenterRegister.fields.users')).nullable(),
});

export type CostCenterForm = y.InferType<typeof costCenterFormSchema>;
