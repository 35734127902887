import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getPaymentMethods } from '../context/queries/paymentMethods';
import { IPaymentMethodsSummary, IPaymentMethodsSummaryResponse } from '../context/types/paymentMethods';

interface IUsePaymentMethods {
  paymentMethods: IPaymentMethodsSummary[];
  isLoading: boolean;
  error: unknown;
}

export const useAllPaymentMethods = (skip: boolean = false): IUsePaymentMethods => {
  const { loading, data, error } = useQuery<IPaymentMethodsSummaryResponse>(getPaymentMethods, { skip });

  return {
    paymentMethods: data?.getPaymentMethods?.paymentMethods || [],
    isLoading: loading,
    error,
  };
};
