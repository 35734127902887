import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { SharedExpenseActions } from '@containers/Expenses/components/organisms/ExpenseActions';
import { ExpensesPreview } from '@containers/Expenses/components/organisms/ExpensesPreview';
import { LatestReport } from '@containers/Expenses/components/organisms/LatestReport';
import { CompanyPayables } from '@flash-hros/company-payables';
import { Tab, TabItens } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { NewFeatureTag } from '@molecules/NewFeatureTag';
import { TipsModalController } from '@molecules/TipsModalController';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useCheckCompanyPayablesEnable } from '@shared/hooks/permissions/companyPayables/useCheckCompanyPayablesEnable';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';
import { EAuthZPermission } from '@shared/types/authZPermission';
const { TransactionTab, BillTab } = CompanyPayables;

export default function ExpensesTabs() {
  const { t } = useTranslation();
  const { checkPlatformPermission } = usePlatformPermissions();
  const isCheckCompanyPayablesEnable = useCheckCompanyPayablesEnable();

  useTrackingScreen('expense_list_screen');
  const transactionViewEnabled = checkPlatformPermission(EAuthZPermission.EXPENSE_TRANSACTION_VIEW);

  const expenseTabs: TabItens[] = [
    {
      label: t('pages.sharedExpenses.tabs.expenses'),
      component: (
        <>
          <LatestReport />
          <ExpensesPreview />
          <TipsModalController page="addExpense" />
        </>
      ),
    },
  ];

  if (isCheckCompanyPayablesEnable)
    expenseTabs.push({
      label: <NewFeatureTag title={t('pages.sharedExpenses.tabs.vendorExpenses')} />,
      component: (
        <ModuleErrorBoundary moduleName="Contas a Pagar">{BillTab ? <BillTab /> : <div />}</ModuleErrorBoundary>
      ),
    });

  if (transactionViewEnabled)
    expenseTabs.unshift({
      label: t('pages.sharedExpenses.tabs.transaction'),
      component: (
        <ModuleErrorBoundary moduleName="Contas a Pagar">
          {TransactionTab ? <TransactionTab /> : <div />}
        </ModuleErrorBoundary>
      ),
    });

  return (
    <BasePage
      headerPage={{
        title: t('pages.expenses.pageTitle'),
        actions: <SharedExpenseActions />,
        faqType: 'expenses',
        showDelegateInfo: true,
      }}>
      {expenseTabs.length > 1 ? (
        <Tab defaultTab={transactionViewEnabled ? 1 : 0} variant="primary" tabItens={expenseTabs} />
      ) : (
        <>
          <LatestReport />
          <ExpensesPreview />
          <TipsModalController page="addExpense" />
        </>
      )}
    </BasePage>
  );
}
