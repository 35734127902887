import React from 'react';
import { Button, IconTypes, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import { TextAlign } from 'src/themes/types/Variants';

import * as SC from './styled';

export interface DefaultModalProps {
  /**
   * if 'true' open the modal
   */
  open: boolean;

  /**
   * if 'true' the button shows loading status
   */
  isLoading?: boolean;

  /**
   * Header text alignment
   */
  headerTextAlign?: TextAlign;

  /**
   * Modal content
   */
  children?: React.ReactNode;

  /**
   * Icon header
   *
   */
  headerIcon?: IconTypes;

  /**
   * Icon Label header
   *
   */
  headerIconLabel?: string;

  /**
   * Title header
   *
   */
  headerTitle?: React.ReactNode;

  /**
   * Subtitle header
   *
   */
  headerSubtitle?: React.ReactNode;

  /**
   * Text or custom item in header
   *
   */
  headerCustom?: React.ReactNode;

  /**
   * Text or custom item in header
   *
   */
  headerUpperItem?: React.ReactNode;

  /**
   * Text or custom item in footer
   *
   */
  footerCustom?: React.ReactNode;

  /**
   * Confirmation button custom text or item
   *
   */
  labelButtonAction?: React.ReactNode;

  /**
   * Displays tooltip text on the action button
   *
   */
  tooltipButtonAction?: string;

  /**
   * Allows Display tooltip text on action button
   *
   */
  showTooltipButtonAction?: boolean;

  /**
   * Cancel button custom text or item
   *
   */
  labelButtonCancel?: React.ReactNode;

  /**
   * if 'true' disable button action
   */
  actionDisabled?: boolean;

  /**
   * if 'true' loading button appears
   */
  isActionLoading?: boolean;

  /**
   * Cancel event
   *
   */
  onCancelClick?(): void;

  /**
   * Close event
   *
   */
  onCloseClick?(): void;

  /**
   * Action event
   *
   */
  onActionClick?(): void;

  /**
   * class name
   *
   */
  className?: string;

  /**
   * container class name
   *
   */
  containerClassName?: string;

  /**
   * body class name
   *
   */
  bodyClassName?: string;

  /**
   * header class name
   *
   */
  headerClassName?: string;

  /**
   * class name
   *
   */
  disableFooter?: boolean;

  /**
   * button type
   *
   */
  buttonType?: 'button' | 'reset' | 'submit';
}

export const DefaultModal = ({
  open,
  isLoading,
  isActionLoading,
  headerTextAlign,
  children,
  headerIcon,
  headerIconLabel,
  headerTitle,
  headerSubtitle,
  headerUpperItem,
  headerCustom,
  footerCustom,
  labelButtonAction,
  labelButtonCancel,
  actionDisabled,
  onCancelClick,
  onCloseClick,
  onActionClick,
  className,
  disableFooter = false,
  containerClassName,
  tooltipButtonAction,
  showTooltipButtonAction = false,
  bodyClassName,
  headerClassName,
  buttonType = 'button',
}: DefaultModalProps) => {
  const { t } = useTranslation();

  return (
    <SC.ModalDefault open={open} onClose={onCloseClick} className={className} scroll="body">
      <SC.ModalContainer className={containerClassName}>
        <SC.ModalHeaderContainer className={headerClassName}>
          {Boolean(headerCustom) ? (
            headerCustom
          ) : (
            <>
              {(onCloseClick || onCancelClick) && <SC.IconClose onClick={onCloseClick || onCancelClick} />}
              {Boolean(headerIcon) && (
                <SC.IconHeaderContainer>
                  <SC.HeaderIcon size="large" variant="secondary" icon={headerIcon} />
                </SC.IconHeaderContainer>
              )}

              {Boolean(headerIconLabel) && <SC.IconLabel>{headerIconLabel}</SC.IconLabel>}
              {Boolean(headerUpperItem) && <SC.HeaderUpperItem>{headerUpperItem}</SC.HeaderUpperItem>}
              {Boolean(headerTitle) && <SC.Title align={headerTextAlign}>{headerTitle}</SC.Title>}
              {Boolean(headerSubtitle) && <SC.Subtitle align={headerTextAlign}>{headerSubtitle}</SC.Subtitle>}
            </>
          )}
        </SC.ModalHeaderContainer>
        {Boolean(children) && <SC.ModalBodyContainer className={bodyClassName}>{children}</SC.ModalBodyContainer>}
        {!disableFooter && (
          <SC.ModalFooterContainer>
            {Boolean(footerCustom) ? (
              footerCustom
            ) : (
              <>
                <div>
                  <LinkButton variant="default" onClick={onCancelClick || onCloseClick}>
                    {labelButtonCancel || t('molecules.defaultModal.cancel')}
                  </LinkButton>
                </div>
                <div>
                  <Tooltip
                    arrow
                    title={tooltipButtonAction && showTooltipButtonAction ? tooltipButtonAction : ''}
                    placement="top-start">
                    <div>
                      <Button
                        type={buttonType}
                        size="large"
                        variant="primary"
                        loading={isLoading || isActionLoading}
                        disabled={actionDisabled}
                        onClick={onActionClick}>
                        {labelButtonAction || t('molecules.defaultModal.confirm')}
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </>
            )}
          </SC.ModalFooterContainer>
        )}
      </SC.ModalContainer>
    </SC.ModalDefault>
  );
};
