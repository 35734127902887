import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import styled from 'styled-components';

export const ModalDescriptionContainer = styled.div`
  width: 460px;
  text-align: center;
  position: relative;
  /* overflow-y: auto;
  max-height: calc(100vh - 299px); */
  padding-right: 25px;
`;

export const ModalButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xl};
`;

export const List = styled.ul`
  text-align: left;
`;

export const FormFieldContainer = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacings.xs} 0;
`;

export const FormFieldTypography = styled(Typography)`
  margin: ${({ theme }) => theme.spacings.xs4} 0;
`;

export const StyledContractModal = styled(DefaultModal)`
  .contract-modal {
    &-header {
      padding: 40px 96px 0px;
      border-bottom: none;
    }

    &-body {
      padding: 10px 96px 40px;
      max-height: calc(-215px + 100vh);
    }
  }
`;
