import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { TravelCorporateTab } from '@flash-expense/flashos-travel-web';

export function TravelTabItem() {
  return (
    <ModuleErrorBoundary moduleName="Travel" title="Erro ao tentar carregar tabs de Viagens Corporativas">
      <TravelCorporateTab />
    </ModuleErrorBoundary>
  );
}
